@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: "Circular Std Book";
	font-style: normal;
	font-weight: 450;
	src: local("Circular Std Book"),
		url("https://fonts.cdnfonts.com/s/14289/CircularStd-Book.woff")
			format("woff");
}
@font-face {
	font-family: "Circular Std Book Italic";
	font-style: italic;
	font-weight: 450;
	src: local("Circular Std Book Italic"),
		url("https://fonts.cdnfonts.com/s/14289/CircularStd-BookItalic.woff")
			format("woff");
}
@font-face {
	font-family: "Circular Std Medium";
	font-style: normal;
	font-weight: 500;
	src: local("Circular Std Medium"),
		url("https://fonts.cdnfonts.com/s/14289/CircularStd-Medium.woff")
			format("woff");
}
@font-face {
	font-family: "Circular Std Medium Italic";
	font-style: italic;
	font-weight: 500;
	src: local("Circular Std Medium Italic"),
		url("https://fonts.cdnfonts.com/s/14289/CircularStd-MediumItalic.woff")
			format("woff");
}
@font-face {
	font-family: "Circular Std Bold";
	font-style: normal;
	font-weight: 700;
	src: local("Circular Std Bold"),
		url("https://fonts.cdnfonts.com/s/14289/CircularStd-Bold.woff")
			format("woff");
}
@font-face {
	font-family: "Circular Std Bold Italic";
	font-style: italic;
	font-weight: 700;
	src: local("Circular Std Bold Italic"),
		url("https://fonts.cdnfonts.com/s/14289/CircularStd-BoldItalic.woff")
			format("woff");
}
@font-face {
	font-family: "Circular Std Black";
	font-style: normal;
	font-weight: 900;
	src: local("Circular Std Black"),
		url("https://fonts.cdnfonts.com/s/14289/CircularStd-Black.woff")
			format("woff");
}
@font-face {
	font-family: "Circular Std Black Italic";
	font-style: italic;
	font-weight: 900;
	src: local("Circular Std Black Italic"),
		url("https://fonts.cdnfonts.com/s/14289/CircularStd-BlackItalic.woff")
			format("woff");
}

@layer components {
	* {
		font-family: "Circular Std Book", "Roboto", sans-serif !important;
	}
}

@layer utilities {
	.bg-graphics {
		background-image: url(https://content-strapi-lms.s3.ap-south-1.amazonaws.com/icons/lines_f077faf53d.svg);
		background-position: 50%;
		background-size: auto;
		background-repeat: no-repeat;
	}
}